.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
    background-color: rgba(48, 131, 255, .2);
}

.table-bordered td.strong-border-right, .table-bordered th.strong-border-right {
    border-right: 2px solid black;
}

.table-bordered td.strong-border-left, .table-bordered th.strong-border-left {
    border-left: 2px solid black;
}

.table-bordered td.strong-border, .table-bordered th.strong-border {
    border-right: 2px solid black;
    border-left: 2px solid black;
}

.table-bordered td.medium-border-right, .table-bordered th.medium-border-right {
    border-right: 1px solid #858788;
}

.table-bordered td.medium-border-left, .table-bordered th.medium-border-left {
    border-left: 1px solid #858788;
}

.table-bordered td.medium-border, .table-bordered th.medium-border {
    border-right: 1px solid #858788;
    border-left: 1px solid #858788;
}

.hidden {
    display:none;
}

.dropzone {
    width : 100%;
    height : 20%;
    border : 1px solid black;
}

.fileUploader {
    background-color: rgba(240, 240, 240, 0.83);
}

.fileUploader:hover {
    background-color: rgb(240, 240, 240);
}
